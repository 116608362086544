import React from "react";
import ReactApexChart from "react-apexcharts";
import { Oval } from "react-loader-spinner"; // Example using react-loader-spinner

const TokenChart = ({ graphData = [], loading }) => {
  // Transform the graphData prop to match the format ApexCharts requires
  const transformedData = graphData.map((item) => ({
    x: new Date(item.timestamp * 1000), // Convert timestamp to JavaScript Date
    y: item.price.toFixed(2), // Use the price value
  }));

  // Chart options (preserving the original design)
  const options = {
    chart: {
      id: "tokenChart",
      background: "#0b2224",
      toolbar: { show: false },
      zoom: { autoScaleYaxis: true },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "#AAB8C5",
        },
      },
      axisBorder: {
        show: true,
        color: "#374151",
      },
      axisTicks: {
        show: true,
        color: "#374151",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#AAB8C5",
        },
        formatter: (value) => value.toFixed(2), 
      },
      axisBorder: {
        show: true,
        color: "#374151",
      },
    },
    grid: {
      borderColor: "#374151",
      strokeDashArray: 4,
    },
    tooltip: {
      theme: "dark",
      x: {
        format: "dd MMM yyyy HH:mm",
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#00c9a7"],
        stops: [0, 100],
      },
    },
    colors: ["#0fc679"],
  };

  const series = [
    {
      name: "Price",
      data: transformedData,
    },
  ];

  // Render loading state if data is still being fetched
  if (loading) {
    return (
      <div className="loading-chart h-400 d-flex align-items-center justify-content-center">
        <Oval
          height={80}
          width={80}
          color="#0fc679"
          visible={true}
          ariaLabel="loading"
          secondaryColor="#4caf50"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }

  return (
    <div id="tokenChart">
      <ReactApexChart options={options} series={series} type="area" height={400} />
    </div>
  );
};

export default TokenChart;
